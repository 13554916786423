
import React, { useState } from "react";
import { IconButton, Badge, Switch } from "@chakra-ui/react";
import { FiSend, FiArrowLeft, FiArrowRight, FiEye, FiGlobe, FiClock } from 'react-icons/fi';
import LogoSection from "../../components/Logo";
import TwitchView from "../../components/Twitch";

const ImageComponent = ({ imgName }) => {
    return (
        <div style={{ width: '350px', height: '248px' }}>
            <img src={`./images/${imgName}.png`} alt="fifa" style={{ borderRadius: '16px', objectFit: 'cover', objectPosition: 'center', width: '100%', height: '100%' }} />
        </div>
    )
}

const BadgeGroup = () => {
    return (
        <div className="flex gap-[10px] bottom-0 self-end">
            <Badge backgroundColor={'#004EEB'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div className="flex gap-1 text-white items-center">
                    <FiEye size={'16px'} />
                    <p className="text-sm">15K</p>
                </div>
            </Badge>
            <Badge backgroundColor={'#6927DA'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div className="flex gap-1 font-aeonik text-white items-center"><FiGlobe size={'16px'} /><p className="text-sm">ENGLISH</p></div>
            </Badge>
            <Badge backgroundColor={'#F79009'} borderRadius={'16px'} padding={'4px 8px'} alignItems={'center'} gap={'4px'} height={'25px'} display={'flex'}>
                <div className="flex gap-1 font-aeonik text-white items-center"><FiClock size={'16px'} /><p className="text-sm">15min ago</p></div>
            </Badge>
        </div>
    )
}

const LiveMatchComponent = ({ name }) => {
    return (
        <div className="flex flex-col gap-6">
            <TwitchView channel={name} />
            <div className="flex gap-[10px]">
                <img src="./images/Avatar.png" alt="avatar" className="object-cover justify-center rounded-full w-[64px] h-[64px]" />
                <div className="flex flex-col gap-3">
                    <div className="flex flex-col gap-1">
                        <p className="text-white font-aeonik text-lg">{name}</p>
                        <p className="text-gray-300 font-aeonik text-sm ">David Beckham</p>
                    </div>
                    <BadgeGroup />
                </div>
            </div>
        </div>
    )
}

function LiveBetting() {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSendMessage = () => {
        if (inputValue.trim() !== "") {
            setMessages([...messages, inputValue]);
            setInputValue("");
        }
    };
    return (
        <div className="flex flex-col py-4 pr-4 pl-4 gap-8 ">
            <div className="md:hidden self-center"><LogoSection logowidth={103} logoheight={60} /></div>
            <div className="flex gap-3 md:flex-row flex-col">
                <div className="flex items-start self-stretch gap-6 md:w-[60%] flex-col">
                    <div className="w-full">
                    <TwitchView className="rounded-2xl object-cover object-center w-full h-full"  channel={'valorant'} />
                        {/* <img src={`./images/Fifa 2023.png`} alt="fifa" className="rounded-2xl object-cover object-center w-full h-full" /> */}
                    </div>
                    <div className="flex justify-between w-full">
                        <div className="flex flex-col gap-1">
                            <p className="text-2xl font-aeonik font-[500] text-gray-50">FIFA 2023</p>
                            <p className="text-xs font-aeonik font-[400] text-gray-300">David Beckham</p>
                        </div>
                        <BadgeGroup />
                    </div>
                    <p className="text-lg font-aeonik font-[400] text-gray-300">Pick the player you believe in and bet your $CASH. Witness the Win: Watch the match and cheer for your champion. Claim Your Rewards: Winners enjoy their well-deserved payout!</p>
                </div>
                <div className="bg-white bg-opacity-10 rounded-3xl h-[600px] flex md:w-[40%] p-3 justify-between flex-col">
                    <div className="flex items-center gap-2">
                        <div className="rounded-full w-[12px] h-[12px] bg-red-500"></div>
                        <p className="text-white font-aeonik font-bold text-2xl">Live Chat</p>
                    </div>
                    <div className="flex flex-col gap-4 items-start self-stretch h-[80%] mt-0">
                        {messages.map((message, index) => (
                            <div className="flex gap-3" key={index}>
                                <img src="./images/Avatar.png" alt="avatar" width="42px" height="42px" className="rounded-full object-cover justify-center" />
                                <div className="flex flex-col gap-2">
                                    <p className="text-xs font-[400] font-aeonik text-white text-opacity-50">David Beckham</p>
                                    <p className="text-sm font-[500] font-aeonik text-white text-opacity-80">{message}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex items-center gap-2 self-stretch w-full ">
                        <input className="rounded-xl py-3 px-6 w-full bg-gray-600 text-white"
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Write Message..."
                        />
                        <IconButton width={'56px'} color={'white'} height={'56px'} backgroundColor={"var(--purple-500, #7A5AF8)"} icon={<FiSend />} onClick={handleSendMessage} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-4">
                <p className=" font-aeonik text-2xl font-[500] text-white">Place your bet</p>
                <div className="flex gap-4 md:flex-row flex-col">
                    <div className="bg-white bg-opacity-10 rounded-2xl flex md:flex-row flex-col md:w-[60%] md:p-6 p-3 self-stretch items-center md:justify-between">
                        <div className="hidden md:flex md:order-1 order-2 flex-col p-6 gap-6 items-center bg-blue-400 bg-opacity-20 rounded-2xl">
                            <img src="./images/playerA.png" alt="avatar" width={'105px'} height={'80px'} />
                            <div className="flex flex-col gap-2 items-center">
                                <p className="text-white text-2xl font-[500]">Team A</p>
                                <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                            </div>
                        </div>
                        <div className="flex md:order-1 order-1 md:flex-col flex-row md:gap-3 p-2 md:w-auto w-full justify-between">
                            <p className="text-2xl font-[500] text-white self-center">Game Score</p>
                            <p className="text-indigo-400 text-[64px] font-bold">0 : 0 </p>
                        </div>
                        <div className="hidden md:flex md:order-1 order-3 flex-col p-6 gap-6 items-center">
                            <img src="./images/playerB.png" alt="avatar" width={'105px'} height={'80px'} />
                            <div className="flex flex-col gap-2 items-center">
                                <p className="text-white text-2xl font-[500]">Team B</p>
                                <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                            </div>
                        </div>
                        <div className="md:hidden flex flex-row md:order-1 order-2 justify-between w-full">
                            <div className="flex  flex-col p-6 gap-6 items-center bg-blue-400 bg-opacity-20 rounded-2xl">
                                <img src="./images/playerA.png" alt="avatar" width={'100px'} height={'80px'} />
                                <div className="flex flex-col gap-2 items-center">
                                    <p className="text-white text-2xl font-[500]">Team A</p>
                                    <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                                </div>
                            </div>
                            <div className="flex flex-col p-6 gap-6 items-center">
                                <img src="./images/playerB.png" alt="avatar" width={'100px'} height={'80px'} />
                                <div className="flex flex-col gap-2 items-center">
                                    <p className="text-white text-2xl font-[500]">Team B</p>
                                    <Badge backgroundColor={'rgba(0, 0, 0, 0.30)'} color={'white'} borderRadius={'4px'} padding={'4px 12px'} width={'54px'} height={'28px'}>3.9x</Badge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white bg-opacity-10 rounded-2xl gap-4 flex md:w-[40%] flex-col p-4">
                        <div className="flex gap-2">
                            <div className="flex flex-col w-[70%] gap-[6px]">
                                <p className="font-Monopol text-14px font-[500] text-gray-50">Bet amount</p>
                                <div className="flex rounded-lg w-full">
                                    <input className="py-[10px] pl-[14px] w-[147px] text-white bg-gray-50 bg-opacity-20 rounded-l-lg" placeholder="1000.00" />
                                    <select className="px-4 py-2 bg-gray-50 bg-opacity-20 text-white rounded-r-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                                        <option value="english">USD</option>
                                        <option value="spanish">CAD</option>
                                        <option value="french">HKD</option>
                                    </select>
                                </div>
                            </div>
                            <input className="py-[10px] pl-[14px] text-white bg-gray-50 bg-opacity-20 rounded-lg w-1/3 self-end" placeholder="3.56" />
                        </div>
                        <div className="flex gap-2 flex-col">
                            <div className="flex justify-between">
                                <p className="text-white font-[400] text-18px">Possible win: </p>
                                <p className="text-white font-bold text-18px">$3560 USDT</p>
                            </div>
                            <div className="flex justify-between">
                                <p className="text-white font-[400] text-18px">Auto-Confirm Odds</p>
                                <Switch id="possible" />
                            </div>
                            <div className="flex justify-between">
                                <p className="text-white font-[400] text-18px">Overall coefficient: </p>
                                <p className="text-white font-bold text-18px">3.56x</p>
                            </div>
                        </div>
                        <button className="bg-gradient-to-r from-red-500 to-purple-500 hover:to-purple-600 text-white py-[10px] px-[18px] rounded-lg shadow-md text-[500]">
                            Place Bet
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex justify-between">
                    <div className="flex items-center gap-[5px]">
                        <div className="rounded-full w-[8px] h-[8px] bg-red-500"></div>
                        <p className="text-white font-aeonik font-bold text-base">LIVE MATCHES</p>
                    </div>
                    <div className="flex gap-[6px]">
                        <button className="bg-black text-white py-[7px] px-[13px]"><FiArrowLeft /></button>
                        <button className="bg-black text-white py-[7px] px-[13px]"><FiArrowRight /></button>
                    </div>
                </div>
                <div className="flex gap-4 md:flex-wrap overflow-auto w-full">
                    <LiveMatchComponent name={'valorant'} />
                    <LiveMatchComponent name={'cutieloony'} />
                    <LiveMatchComponent name={'k3soju'} />
                    <LiveMatchComponent name={'tfblade'} />
                </div>
            </div>
        </div>
    )
}

export default LiveBetting;