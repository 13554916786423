import * as React from 'react'
import { TwitchPlayerNonInteractive } from 'react-twitch-embed'
import './styles.css'

const TwitchView = ({ key, channel}) => {
  // let showChat = useIsStreaming(channel)
  const [isOnline, setOnline] = React.useState(false)
  const player = React.createRef()
  const stopPlaying = () => player.current?.pause()

  return (
    <div>
      <div>
        <TwitchPlayerNonInteractive
         key={key}
          channel={channel}
          width="100%"
          onOnline={() => setOnline(true)}
          onOffline={() => setOnline(false)}
          onReady={(p) => {
            player.current = p
          }}
        />
      </div>
      
    </div>
  )
}
export default TwitchView;

// export default function Twitch() {
//   return (
//     <div className="App">
//       <TwitchView channel="valorant" displayName="The Entire Robot" />
//     </div>
//   )
// }
